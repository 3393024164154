import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`NOTIFICATIONS THAT DELIVER
Beams is a hosted push notifications API designed for developers who need critical transactional information delivered every time.`}</p>
    <p>{`Get your free accountRead the docs`}</p>
    <p>{`NOTIFY USERS WITH CONFIDENCE
Beams provides realtime delivery and transparency for your iOS and Android apps. Reach users instantly with expert notification deliverability across devices and locations at scale.`}</p>
    <p>{`VIRGINPULSE1m ago
Walking Goal Update
You’ve passed 10,000 steps today. Walk 1,317 more to pass Susan`}</p>
    <p>{`DKLIVE1m ago
Touchdown Alert
Matt Ryan 78 yard touchdown pass to Julio Jones`}</p>
    <p>{`USER CONFERENCE2m ago
Call Starting
Call with `}<a parentName="p" {...{
        "href": "mailto:team@yourcompany.com"
      }}>{`team@yourcompany.com`}</a>{` about Goal Planning from 9:30-10:30 EST is starting now`}</p>
    <p>{`FLEXIBLE DELIVERY
Trigger notifications automatically with every event in your app using a single function.`}</p>
    <p>{`Node.JSGoPythonJavaKotlinRuby
PUBLISH API`}</p>
    <p>{`1
2
3
4
5
6
7
8
9
10
11
12
13
14
15
16
17
18
19
20
21
22
const beamsClient = new PushNotifications({
instanceId: 'YOUR_INSTANCE_ID_HERE',
secretKey: 'YOUR_SECRET_KEY_HERE'
});`}</p>
    <p>{`beamsClient.publishToInterests(`}{`['hello']`}{`, {
apns: {
aps: {
alert: 'Hello!'
}
},
fcm: {
notification: {
title: 'Hello',
body: 'Hello, world!'
}
}
}).then((publishResponse) => {
console.log('Just published:', publishResponse.publishId);
}).catch((error) => {
console.error('Error:', error);
});
Delivery to iOS and Android users with one request`}</p>
    <p>{`High volume throughput and batch support`}</p>
    <p>{`Automatic scaling`}</p>
    <p>{`Support for common edge cases`}</p>
    <p>{`Compatible with server SDKs in PHP, Node, Java, and more`}</p>
    <p>{`Travis Dunn`}</p>
    <p>{`CTO, DraftKings`}</p>
    <p>{`Beams gives us an elegant API abstraction to send realtime scoring notifications personalized by user preference and gameplay. We strive to give fantasy players a flawless live sports experience, which Pusher delivers.`}</p>
    <p>{`OBSERVABLE HEALTH
Debug your implementation and observe your delivery health with Debug Console and Insights.`}</p>
    <p>{`Notifications acknowledged by devices`}</p>
    <p>{`Notifications opened by users`}</p>
    <p>{`Realtime debug console`}</p>
    <p>{`Device
Acknowledgements
99.58
%
Today
Your device acknowledgements
have been stable for the last 7 days
Live Event Log
Notification Opened
17:15:53
1 android device
Device ID
Fcm- 284cfa1b-b203-4
Notification Opened
17:14:47
1 iOS device
Device ID
Apns- 5f27j1b-46hd-4
Notifications Opened
175,045
Last 7 Days
1
2
3
4
5
6
7
EASY INTEGRATION
We host and manage your complete device token lifecycle.`}</p>
    <p>{`iOSAndroid
INTEGRATE CLIENT SDK`}</p>
    <p>{`1
2
3
4
5
6
7
8
9
10
11
12
13
14
15
16
17
18
19
let pushNotifications = PushNotifications.shared`}</p>
    <p>{`func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: `}{`[UIApplicationLaunchOptionsKey: Any]`}{`?) -> Bool {
self.pushNotifications.start(instanceId: "YOUR_INSTANCE_ID")
self.pushNotifications.registerForRemoteNotifications()
try? self.pushNotifications.subscribe(interest: "hello")`}</p>
    <pre><code parentName="pre" {...{}}>{`return true
`}</code></pre>
    <p>{`}`}</p>
    <p>{`func application(_ application: UIApplication, didRegisterForRemoteNotificationsWithDeviceToken deviceToken: Data) {
self.pushNotifications.registerDeviceToken(deviceToken) {
print("Ready to receive notifications!")
}
}`}</p>
    <p>{`func application(_ application: UIApplication, didReceiveRemoteNotification userInfo: `}{`[AnyHashable: Any]`}{`, fetchCompletionHandler completionHandler: @escaping (UIBackgroundFetchResult) -> Void) {
self.pushNotifications.handleNotification(userInfo: userInfo)
}
Supported iOS and Android SDKs`}</p>
    <p>{`Managed device token and Interest database`}</p>
    <p>{`Offline support`}</p>
    <p>{`Automatic retry handling`}</p>
    <p>{`BEAMS SUPPORTS
OWN YOUR APP DATA
Beams is GDPR compliant and we will never sell your users’ data to advertisers or research companies.`}</p>
    <p>{`Fast & easy set up
3
/4
developers
get a test notification service
set up
in 30 minutes or less
GET STARTED WITH NEXT-LEVEL PUSH NOTIFICATIONS
Have delivery problems with your current solution? Put us to the test. No credit card required.`}</p>
    <p>{`Get your free accountCONTACT SALES`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      